.body-page {
  display: flex;
  background-color: white;
  height: 1920px;
  width: 100%;
  position: relative;
}

.sidebar {
  position: fixed;
  width: 314px;
  height: 507px;
  background-color: #EDEDED;
  padding: 10px;
  top: 28px;
  left: 26px;
  margin-bottom: 25px;
  overflow-y: auto;
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar button {
  background-color: #EDEDED;
  color: black;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* .content{
  overflow-x: auto !important;
} */

#content {
  overflow-x: auto;
  overflow-y: hidden;
}

.organization-list {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.details-header {
  background-color: #15283c;
  color: white;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 24px;
}

.flex-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 0 0 48%;
  /* Adjusts size to fill roughly half the parent container */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
textarea {
  /* padding: 10px; */
  border: 1px solid #ced4da;
  border-radius: 5px;
  color: #495057
}

.save-button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.manage-list {
  width: 100%;
  margin-left: 0px;
  padding: 20px;
}

.tabs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  margin-bottom: 0px;
}

.admin-list,
.admin-details {
  width: 100%;
  margin-left: 0px;
  padding: 20px;
}

.organization-add {
  width: 120px;
  border-radius: 30px;
  background: #9EC5FF;
  color: black;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}

.organization-add :hover {
  cursor: pointer;
}

.invite-admin {
  color: white;
  margin-left: 50px;
  font-weight: 500;
  width: 150px;
  border-radius: 2px;
  background: var(--Black, #0F172A);

}

.delete-icon {
  width: 16px;
  height: 16px;
}

.delete-icon :hover {
  cursor: pointer;
}

.invite-admin :hover {
  cursor: pointer;
}

.search-wrapper {
  position: relative;
}

.search-input {
  width: 180px;
  height: 40px;
  padding-right: 30px;
  /* 为图标留出空间 */
  padding-left: 5px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url('../images/search-icon-2.png');
  /* 使用图片作为搜索图标 */
  background-size: cover;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  margin: 5px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background: var(--Grey-Light, #E2E8F0);
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}


.bg-color {
  width: 100%;
  background-color: #fff;
}

.close-button {
  position: relative;
  top: -10px;
  right: -10px;
  background-color: transparent;
  color: black;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}





.invite-admin-checkbox {
  margin-left: 50px;
  margin-bottom: 10px;
}

.invite-admin-checkbox,
.edit-admin-checkbox label {
  font-size: 15px;
  font-weight: 700;
  margin-top: 20px;
  display: block;
  margin-bottom: 5px;
}

.invite-admin-checkbox input {
  width: 5%;
  padding: 0px;
}



hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;

}


.back-button {
  height: 80px;
  width: 80px;
  margin-left: 0;
  margin-top: 0;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none !important;
  padding: 30px 30px !important;
  -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
  transition: background-color 1s, transform 0.5s;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  -webkit-transform: translateX(-5px);
  -webkit-box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
  -moz-box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
  box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
}

.users-button,
.details-button {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  outline: none;
}

.users-button.active,
.details-button.active {
  background-color: #007BFF;
  color: white;
}

.users-button:hover,
.details-button:hover {
  background-color: #e2e2e2;
}


.invite-user {
  padding: 10px 25px;
  background-color: #4CAF50;
  /* Green background */
  color: white;
  /* White text */
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.2s;
  /* Optional: makes the text uppercase */
}

.invite-user:hover {
  background-color: #45a049;
  /* Darker shade of green */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Adds a shadow when hovered */
}

.invite-user:active {
  background-color: #3e8e41;
  /* Even darker green when clicked */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  /* Smaller shadow for a "pushed" effect */
}

.invite-user:focus {
  outline: none;
  /* Removes the outline to keep the design clean */
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 0 4px #4CAF50;
  /* Adds a focus outline for accessibility */
}

.detail-input {
  margin-top: 20px;
  margin-bottom: 10px;
}

.detail-input label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}

.detail-input input {
  display: block;
  width: 40%;
  padding: 8px;
  border: 2px solid black;
  border-radius: 0px;
}

.save-button {
  margin-top: 50px;
  margin-left: 200px;
  font-weight: 700;
  width: 120px;
  border-radius: 2px;
  color: white;
  background: var(--Black, #0F172A);
}

.role-select {
  /* width: 265px; */
  width: calc(100% - 20px);
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.admin-modal-checkbox {
  width: 30px !important;
}

.admindetail-checkbox {
  display: flex !important;
  width: 30px !important;
}

.admin-detail-head {
  margin-top: 40px;
}

.modal {
  display: flex !important; 
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

/* Modal 内容区域 */
.modal-content {
  background-color: #fff;
  padding: 20px;
  width: 120%;
  border-radius: 5px;
  max-width: 500px;
}

@media screen and (max-width: 768px) {
  .content {

    overflow-x: hidden;
  }
}

.user-photo {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 20%;
  margin-right: 20px;
}


.add_devices_instruction_list_wrap {
  padding: 100px;
}

.add_devices_instruction_list {
  margin-bottom: 50px;
  counter-reset: list-number;
  list-style-type: none;
}

.add_devices_instruction_heading,
.add_devices_instruction {
  font-size: 15px;
  line-height: 2;
  font-weight: 400;
  color: #000;
  width: 80%;
  margin-bottom: 50px;
  display: inline-flex;
  align-items: center;
}

.add_devices_instruction:before {
  counter-increment: list-number;
  content: counter(list-number);

  margin-right: 30px;
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #0365F4;
  color: #0365F4;
}

.link-primary {
  color: #0365F4;
}


